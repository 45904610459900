/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('./src/style/main.scss');
const { registerLinkResolver } = require('gatsby-source-prismic-graphql');
const { linkResolver } = require('./src/link-resolver');

registerLinkResolver(linkResolver);