module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/gatsby-icon.png"},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"iclglobal","defaultLang":"en-us","path":"/preview","previews":true,"pages":[{"type":"BlogPost","match":"/blog/:uid","path":"/","component":"C:\\GitLab-Runner\\builds\\-LEkT6Xs\\0\\atlanticglobal\\icl-gatsby\\src\\templates\\blog-post.js"},{"type":"LandingPage","match":"/landing/:uid","path":"/","component":"C:\\GitLab-Runner\\builds\\-LEkT6Xs\\0\\atlanticglobal\\icl-gatsby\\src\\templates\\landing-page.js"},{"type":"ContentPage","match":"/:uid","path":"/","component":"C:\\GitLab-Runner\\builds\\-LEkT6Xs\\0\\atlanticglobal\\icl-gatsby\\src\\templates\\content-page.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
